.quiz-medal {
    margin: 30px 0 0 30px;
  }
  // CSS reset END
  
  $gold-medal: #f9ad0e;
  $silver-medal: #d1d7da;
  $bronze-medal: #df7e08;
  $neutral-medal: #d1d7da;
  
  .quiz-medal {
    position: relative;
    margin-bottom: 16px;
    min-width: 250px;    
    background-color: #f9ad0e;
  }
  
  .quiz-medal__circle {
    font-family: 'Roboto', sans-serif;
    font-size: 80px;
    font-weight: 500;
    width: 150px;
    height: 150px;
    border-radius: 100%;
    color: white;
    text-align: center;
    line-height: 130px;
    vertical-align: middle;
    position: relative;
    border-width: 0.1em;
    border-style: solid;
    z-index: 1;
    cursor: pointer;
  
    // Default colors
    box-shadow: inset 0 0 0 darken($neutral-medal, 15%), 2px 2px 0 rgba(0, 0, 0, 0.08);
    border-color: lighten(adjust-hue($neutral-medal, 10), 10%);
    text-shadow: 2px 2px 0 darken($neutral-medal, 20%);
    background: linear-gradient(to bottom right, $neutral-medal 50%, darken($neutral-medal, 5%) 50%);
  
    &.quiz-medal__circle--gold {
      box-shadow: inset 0 0 0 darken($gold-medal, 15%), 2px 2px 0 rgba(0, 0, 0, 0.08);
      border-color: lighten(adjust-hue($gold-medal, 10), 10%);
      text-shadow: 0 0 4px darken($gold-medal, 20%);
      background: linear-gradient(to bottom right, $gold-medal 50%, darken($gold-medal, 5%) 50%);
    }
  
    &.quiz-medal__circle--silver {
      box-shadow: inset 0 0 0 darken($silver-medal, 15%), 2px 2px 0 rgba(0, 0, 0, 0.08);
      border-color: lighten(adjust-hue($silver-medal, 10), 10%);
      text-shadow: 0px 0px 4px darken($silver-medal, 20%);
      background: linear-gradient(to bottom right, $silver-medal 50%, darken($silver-medal, 5%) 50%);
    }
  
    &.quiz-medal__circle--bronze {
      box-shadow: inset 0 0 0 darken($bronze-medal, 15%), 2px 2px 0 rgba(0, 0, 0, 0.08);
      border-color: lighten(adjust-hue($bronze-medal, 10), 10%);
      text-shadow: 0 0 4px darken($bronze-medal, 20%);
      background: linear-gradient(to bottom right, $bronze-medal 50%, darken($bronze-medal, 5%) 50%);
    }
  }
  
  .quiz-medal__ribbon {
    content: "";
    display: block;
    position: absolute;
    border-style: solid;
    border-width: 6px 10px;
    width: 0;
    height: 20px;
    top: 50px;
  }
  
  .quiz-medal__ribbon--left {
    border-color: #FC402D #FC402D transparent #FC402D;
    left: 8px;
    transform: rotate(20deg) translateZ(-32px);
  }
  
  .quiz-medal__ribbon--right {
    left: 28px;
    border-color: darken(#FC402D, 10%) darken(#FC402D, 10%) transparent darken(#FC402D, 10%);
    transform: rotate(-20deg) translateZ(-48px);
  }