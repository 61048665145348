// Custom.scs by TOM
// Option A: Include all of Bootstrap

// Include any default variable overrides here (though functions won't be available)
// $navbar-padding-y: 2px;
// $primary: #008854;
// $secondary: #96aed0;
// $success: #30bfbf;
// $info: #45b1e8;
// $danger: #c8102e;
// $dark: #0f2e44;
// $warning: rgb(227, 242, 126);
// $warning_orange: rgb(242, 157, 126);
// $danger: #ff4136;
// $primary: teal;
// $secondary: green;
// $theme-colors: (
//   "primary":    $primary,
//   "secondary":  #2fe3f0,
//   "success":    $success,
//   "info":       $info,
//   "warning":    $warning,
//   "danger":     $danger,
//   "light":      $light,
//   "dark":       $dark
// );
// $body-bg: #000;
// $body-color: #111;

@import url("https://fonts.googleapis.com/css2?family=Kanit:wght@100;200;300;500;700&family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import "../node_modules/bootstrap/scss/bootstrap";

// Then add additional custom code here

body,
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "Poppins", "kanit", "Open Sans", sans-serif;
}

// custiom style

.nav-link {
    font-size: 0.8em;
    color: rgba(255, 255, 255, 0.8);
}

.dropdown-item {
    font-size: 0.8em;
}

.TblResult .idpaNumber {
    font-weight: 700;
    letter-spacing: 3px;
    color: $primary;
}

.TblShooterLink {
    text-decoration: none;
    color: dark;
}