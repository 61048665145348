.quiz-medal {
  margin: 30px 0 0 30px;
}

.quiz-medal {
  position: relative;
  margin-bottom: 16px;
  min-width: 250px;
  background-color: #f9ad0e;
}

.quiz-medal__circle {
  font-family: "Roboto", sans-serif;
  font-size: 80px;
  font-weight: 500;
  width: 150px;
  height: 150px;
  border-radius: 100%;
  color: white;
  text-align: center;
  line-height: 130px;
  vertical-align: middle;
  position: relative;
  border-width: 0.1em;
  border-style: solid;
  z-index: 1;
  cursor: pointer;
  box-shadow: inset 0 0 0 #a7b2b8, 2px 2px 0 rgba(0, 0, 0, 0.08);
  border-color: #edeff1;
  text-shadow: 2px 2px 0 #98a6ad;
  background: linear-gradient(to bottom right, #d1d7da 50%, #c3cbcf 50%);
}
.quiz-medal__circle.quiz-medal__circle--gold {
  box-shadow: inset 0 0 0 #b67d05, 2px 2px 0 rgba(0, 0, 0, 0.08);
  border-color: #fadd40;
  text-shadow: 0 0 4px #9d6c04;
  background: linear-gradient(to bottom right, #f9ad0e 50%, #e89f06 50%);
}
.quiz-medal__circle.quiz-medal__circle--silver {
  box-shadow: inset 0 0 0 #a7b2b8, 2px 2px 0 rgba(0, 0, 0, 0.08);
  border-color: #edeff1;
  text-shadow: 0px 0px 4px #98a6ad;
  background: linear-gradient(to bottom right, #d1d7da 50%, #c3cbcf 50%);
}
.quiz-medal__circle.quiz-medal__circle--bronze {
  box-shadow: inset 0 0 0 #955405, 2px 2px 0 rgba(0, 0, 0, 0.08);
  border-color: #f7bb23;
  text-shadow: 0 0 4px #7d4604;
  background: linear-gradient(to bottom right, #df7e08 50%, #c67007 50%);
}

.quiz-medal__ribbon {
  content: "";
  display: block;
  position: absolute;
  border-style: solid;
  border-width: 6px 10px;
  width: 0;
  height: 20px;
  top: 50px;
}

.quiz-medal__ribbon--left {
  border-color: #FC402D #FC402D transparent #FC402D;
  left: 8px;
  transform: rotate(20deg) translateZ(-32px);
}

.quiz-medal__ribbon--right {
  left: 28px;
  border-color: #f31903 #f31903 transparent #f31903;
  transform: rotate(-20deg) translateZ(-48px);
}/*# sourceMappingURL=prizeMedal.css.map */